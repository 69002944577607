import { Link } from "gatsby";
import React from "react";
import style from "../scss/components/BlogRoll.module.scss";
const BlogCard = class extends React.Component {
  render() {
    const post = this.props.post;
    return (
      <Link className={style.blog_list__item} to={post.fields.slug}>
        <div>
          {post.frontmatter.featuredimage && (
            <img
              className={style.blog_list__item__img}
              src={post.frontmatter.featuredimage.childImageSharp.fluid.src}
              alt={`thumbnail ${post.frontmatter.title}`}
            />
          )}
        </div>
        <div className={style.blog_list__item__text}>
          <div className={style.blog_list__item__text__title}>
            {post.frontmatter.title}
          </div>
        </div>
      </Link>
    );
  }
};
export default BlogCard;
